import React, {useCallback, useEffect, useState} from 'react';

import {useGetUnreadConversations} from '../../server/react-query';
import {useQueryClient} from 'react-query';
import {useDialog} from '../../../core/components/dialog';
import InAppCallNotificationView from '../../pages/call-room/InAppCallNotificationView';
import {useAuth} from '../../hooks/useAuth';
import {RoutePath} from '../../navigation/config/RouteConfig';
import {useLocation} from 'react-router-dom';

const UnreadChats = () => {
  const {dismissDialog, presentDialog} = useDialog();
  const {authData} = useAuth();
  const location = useLocation();

  const [unreadConversationsCount, setUnreadConversationsCount] =
    useState<number>(0);
  const [intervalRef, setIntervalRef] =
    useState<NodeJS.Timeout | undefined>(undefined);

  const queryClient = useQueryClient();
  const {data} = useGetUnreadConversations();

  useEffect(() => {
    return () => intervalRef && clearInterval(intervalRef);
  }, [intervalRef]);

  useEffect(() => {
    if (data) {
      setUnreadConversationsCount(data.length);

      if (data.length && data[0].contents.length > 0) {
        const latestContent = data[0].contents;
        const contentString = latestContent[0].content;

        // Check if the content type matches video or voice
        if (
          contentString.includes('KAZAM_SYSTEM_VIDEO_CALL') ||
          contentString.includes('KAZAM_SYSTEM_VOICE_CALL')
        ) {
          // Ensure that createdOn (from content) is a valid date string and then convert it to a Date object
          const contentTime = new Date(data[0].contents[0].createdOn);

          // Check if contentTime is a valid date
          if (!isNaN(contentTime.getTime())) {
            const currentTime = new Date();

            // Calculate the difference in milliseconds
            const timeDifference =
              currentTime.getTime() - contentTime.getTime();

            // Convert difference to minutes and check if within 1 minute
            if (timeDifference <= 60000) {
              const channelId = data[0].contents[0].conversationChannelId;
              const contentId = data[0].contents[0].conversationContentId;

              let callType: any;

              switch (contentString) {
                case 'KAZAM_SYSTEM_VIDEO_CALL':
                  callType = 'video';
                  break;
                case 'KAZAM_SYSTEM_VOICE_CALL':
                  callType = 'voice';
                  break;
              }

              // `meta` contains status if call is `accepted`, `pending`, `declined`, and `ended`.
              // `meta` will remain undefined throughout the calling.
              const meta = data[0].contents[0].meta;
              if (meta) {
                const parsedMeta = JSON.parse(meta);

                if (
                  parsedMeta.status === 'accepted' ||
                  parsedMeta.status === 'ended' ||
                  parsedMeta.status === 'declined'
                ) {
                  dismissDialog();
                }
              }

              if (!meta && latestContent[0].userId !== authData?.user.userId) {
                showCallingDialog(channelId, contentId, callType);
              }
            }
          }
        }
      }
    }
  }, [data]);

  const showCallingDialog = (
    id: string,
    contentId: string,
    callType: 'video' | 'voice',
  ) => {
    presentDialog({
      headerText: '',
      enableBackdropDismiss: false,
      hideClose: true,
      fullWidth: true,
      hideHeader: true,
      useFullScreen: true,
      content: <InAppCallNotificationView id={id} callType={callType} />,
    });
  };

  const fetchData = useCallback(() => {
    const intervalRef = setInterval(async () => {
      await queryClient.fetchQuery('get-unread-conversations');
    }, 10_000);
    setIntervalRef(intervalRef);
  }, [queryClient]);

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ location.pathname.includes(RoutePath.MATCHES_LANDING):", !location.pathname.includes(RoutePath.MATCHES_LANDING))
    console.log("🚀 ~ UnreadChats ~ fetchData:", fetchData)
    if (!location.pathname.includes(RoutePath.MATCHES_LANDING)) {
      fetchData();
    }
  }, [fetchData]);

  return <></>;
};

export default UnreadChats;
