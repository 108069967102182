import {isMobile} from 'react-device-detect';
import styled, { css } from 'styled-components';
import { colorTheme } from '../../../../core/configs';
import homeownerBg from '../../../../assets/homeowner-bg.webp';
import kasambahayBG from '../../../../assets/kasambahay-bg.webp';
import { isUsingMobileBrowser } from '../../../utils/random.util';

export const StyledBlogSectionWrapper = styled.div`
  ${isUsingMobileBrowser()
    ? css`
        height: calc(100dvh - 55px - env(safe-area-inset-top));
        padding: 36px 0 36px 0;
        justify-content: space-between;
      `
    : isMobile
    ? css`
        height: calc(100vh - 55px - env(safe-area-inset-top));
        padding: 36px 0 36px 0;
        justify-content: space-between;
      `
    : css`
        height: auto;
        padding: 52px;
      `}
    background: linear-gradient(180deg, #A070BF 0%, #7B87D4 100%);
    gap: 48px;
    position: relative;

    .carousel.carousel-slider {
      overflow: unset;
      height: 80%;
      position: unset;
      display: flex;
    }

    .carousel .control-dots {
      display: flex;
      justify-content: center;
    }

    .carousel .control-dots {
      bottom: 112px;    
    }

    .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
      background-color: #998CFF;
    }

    .carousel .control-dots .dot {
      opacity: 1;
      width: 12px;
      height: 12px;
      background-color: #e2e2e2;
      opacity: 1;
      border: 1px solid #e2e2e2;
      border-radius: 50%;
      content: '';
      box-shadow: none;
    }
`;

export const StyledSubHeader = styled.div`
  ${isMobile
    ? css`
        font-size: 0.75rem;
        padding: 0 24px 0 24px;
      `
    : css`
        font-size: 1rem;
      `}

  letter-spacing: 0rem;
  font-weight: 400;
  text-align: center;
  color: ${colorTheme.light};
`;

export const StyledHeader = styled.div`
  ${isMobile
    ? css`
        font-size: 1.25rem;
        padding: 0 24px 0 24px;
      `
    : css`
        font-size: 1.953rem;
      `}

  letter-spacing: 0rem;
  font-weight: 700;
  text-align: center;
  color: ${colorTheme.light};
`;

export const StyledBlogWrapper = styled.div`
  height: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;
  border: 1px;
  border-radius: 12px;
  background-color: #E2E2E2;
  max-width: ${isMobile ? '100%' : '350px'};
  position: relative;

  & img {
    object-fit: cover;
  }
`;
