import { IVersionInfo } from './core/models/IVersionInfo';

export class KazamVersionInfo implements IVersionInfo {
  name = process.env.REACT_APP_NAME || "Kazam";
  version = process.env.REACT_APP_VERSION || "1.5.1";
  build = process.env.REACT_APP_BUILD || "1813";
  baseUrl = process.env.REACT_APP_BASE_URL || "@BASE_URL";
  target = process.env.REACT_APP_TARGET || "prod";
  hash = process.env.REACT_APP_HASH || "8503c491c4e03c771504043456f2da6d706f3a00";
  branch = process.env.REACT_APP_BRANCH || "master";

  public toString(): string {
    return `${this.name} ${this.version} b${this.build}`;
  }
}

export const VersionInfo = new KazamVersionInfo();
