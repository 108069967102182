import {useEffect, useState} from 'react';
import {
  Button,
  TextArea,
  Toggle,
  Typography,
} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {usePutAdminControl} from '../../../server/react-query';
import {useToast} from '../../../../core/components/toast';
import {
  AdminControl,
  AdminControlReqBody,
} from '../../../server/types/admin-control.types';

import {StyledWrapper} from './styled';

interface VideoSettingsViewProps {
  data: AdminControl[];
}

const VideoSettingsView = ({data}: VideoSettingsViewProps) => {
  const {presentToast} = useToast();

  const [
    enableNewHomeownerCallSmsNotification,
    setNewHomeownerCallSmsNotification,
  ] = useState(false);
  const [text, setText] = useState<string>('');

  const putAdminControl = usePutAdminControl();

  useEffect(() => {
    if (data) {
      var genericText = data.find(
        d => d.type === 'newHomeownerCallSmsNotification',
      );

      if (genericText) {
        setNewHomeownerCallSmsNotification(genericText.enabled);
        setText(genericText.text);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onEnableAutoMessage = async (value: boolean) => {
    try {
      const data: AdminControlReqBody = {
        type: 'newHomeownerCallSmsNotification',
        enabled: value,
        text: text,
      };
      await putAdminControl.mutateAsync(data);
      setNewHomeownerCallSmsNotification(value);

      presentToast({
        message: `Successfully ${
          value ? 'enabled' : 'disabled'
        } referral text control.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
    }
  };

  const insertContent = (value: string) => {
    var currentContent = text + value;
    setText(currentContent);
  };

  const onPutAdminControl = async () => {
    try {
      const data: AdminControlReqBody = {
        type: 'newHomeownerCallSmsNotification',
        text: text,
        enabled: true,
      };

      await putAdminControl.mutateAsync(data);

      presentToast({
        message: `Successfully saved.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <>
      <StyledWrapper>
        <Typography
          label="CALL SETTINGS"
          variant="f1"
          weight="semibold"
          color={colorTheme.black}
        />

        <div className="pt-3 flex flex-row justify-between items-center">
          <div>
            <Typography
              label="Enable SMS notification for voice or video call to kazam from homeowners"
              variant="f3"
              color={
                enableNewHomeownerCallSmsNotification
                  ? colorTheme.dark
                  : colorTheme.lightGray
              }
            />
            <Typography
              label="Set the generic SMS content the kazam will receive when when the homeowner initiates a voice or video call."
              variant="f1"
              color={
                enableNewHomeownerCallSmsNotification
                  ? colorTheme.darkFaded
                  : colorTheme.lightGray
              }
            />
          </div>
          <div>
            <Toggle
              value={enableNewHomeownerCallSmsNotification}
              onToggle={onEnableAutoMessage}
            />
          </div>
        </div>

        {enableNewHomeownerCallSmsNotification && (
          <div>
            <div className="pt-3 flex flex-row justify-between items-center">
              <TextArea
                placeholder="Enter generic text"
                rows={2}
                maxRows={2}
                autoresize
                bordered
                value={text}
                onChange={value => setText(value.currentTarget.value)}
              />
            </div>

            <div className="pt-3 flex flex-row justify-between items-center">
              <div className="flex space-x-4">
                <div onClick={() => insertContent('<Kasambahay>')}>
                  <Typography
                    className="cursor-pointer"
                    label="Insert Kasambahay's first name"
                    variant="f2"
                    weight="semibold"
                    color={colorTheme.primary}
                  />
                </div>

                <div onClick={() => insertContent('<Homeowner>')}>
                  <Typography
                    className="cursor-pointer"
                    label="Insert Homeowner's first name"
                    variant="f2"
                    weight="semibold"
                    color={colorTheme.primary}
                  />
                </div>
              </div>
            </div>

            <div className="pt-3 flex justify-end">
              <div style={{width: 'fit-content'}}>
                <Button
                  label="Save"
                  color="primary"
                  onClick={onPutAdminControl}
                  isLoading={putAdminControl.isLoading}
                />
              </div>
            </div>
          </div>
        )}
      </StyledWrapper>
    </>
  );
};
export default VideoSettingsView;
