import {useState, useEffect} from 'react';
import {getToken} from '../../configs/firebaseConfig';
import {useAuth} from '../../hooks/useAuth';
import {
  usePatchUserProfileFCMToken,
  usePostFcmToken,
} from '../../server/react-query';
import firebase from 'firebase/app';
import {useToast} from '../../../core/components/toast';
import {FcmTokenBody} from '../../server/types';
import {useDialog} from '../../../core/components/dialog';
import InAppCallNotificationView from '../../pages/call-room/InAppCallNotificationView';

const Notifications = () => {
  const {authData} = useAuth();
  const {presentToast} = useToast();
  const {presentDialog} = useDialog();

  const [isTokenFound, setTokenFound] = useState(false);
  const patchUserProfileFCMToken = usePatchUserProfileFCMToken();
  const postFcmToken = usePostFcmToken();

  // To load once
  useEffect(() => {
    let data: any;

    async function tokenFunc() {
      data = await getToken(setTokenFound);
      if (data) {
        if (authData && authData.user && authData.user.userRole != 'admin') {
          const body: FcmTokenBody = {
            type: 'web',
            deviceId: '',
            token: data,
          };

          const res = await postFcmToken.mutateAsync(body);
          localStorage.setItem('fcmTokenId', res.fcmTokenId);
          console.log('Token generated!');
        }
      }
      return data;
    }

    tokenFunc();
  }, [setTokenFound, authData]);

  if (firebase.messaging.isSupported()) {
    firebase.messaging().onMessage(payload => {
      console.log('notification received: ', payload);

      if (payload.data.type !== 'Chat' && !payload.data.type.includes('Call')) {
        presentToast({
          title: payload.data.title,
          message: payload.data.body,
          notificationType: payload.data.type,
          notifFromUserId: payload.data.fromUserId,
          variant: 'notification',
          position: 'top',
        });
      }

      if (payload.data.type.includes('Call')) {
        // separate checking for system notifications
        const callType = payload.data.type === 'VideoCall' ? 'video' : 'voice';

        showCallingDialog(payload.data.fromUserId, callType);
      }

      // presentToast({
      //   title: payload.data.title,
      //   message: payload.data.body,
      //   notificationType: payload.data.type,
      //   notifFromUserId: payload.data.fromUserId,
      //   variant: 'notification',
      //   position: 'top',
      // });
    });
  }

  const showCallingDialog = (id: string, callType: 'video' | 'voice') => {
    presentDialog({
      headerText: '',
      enableBackdropDismiss: false,
      hideClose: true,
      fullWidth: true,
      hideHeader: true,
      useFullScreen: true,
      content: <InAppCallNotificationView id={id} callType={callType} />,
    });
  };

  return <></>;
};

export default Notifications;
