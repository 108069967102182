import React, {useEffect, useRef, useState} from 'react';
import CallNavbarComponent from './CallNavbarComponent';
import {useAuth} from '../../hooks/useAuth';
import {useHistory} from 'react-router-dom';
import {useGetConversationById, useGetLatestConversationContent, usePutConversationContent} from '../../server/react-query';
import {UserResponse} from '../../server/types';
import {Icon, Typography} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import UserOnlineStatus from '../../components/online-status/UserOnlineStatus';
import StyleStatusIcon from '../../../core/components/profile-status/styled';
import {StyledInAppCallNotifPage} from './styled';
import {useDialog} from '../../../core/components/dialog';
import {RoutePath} from '../../navigation/config/RouteConfig';
import RingingTone from '../../../assets/ringing_tone.wav';
import { CallMeta } from '../../server/types/conversation.types';
import BetaSection from './BetaSection';

interface InAppCallNotificationViewParams {
  id: string;
  contentId?: string
  callType: 'voice' | 'video';
}

const InAppCallNotificationView = ({
  id,
  contentId,
  callType,
}: InAppCallNotificationViewParams) => {
  const {authData} = useAuth();
  const {dismissDialog} = useDialog();
  const history = useHistory();
  const [receiver, setReceiver] = useState<UserResponse>();
  const [callEnded, setCallEnded] = useState<boolean>(false);

  const {
    data: latestContent,
    refetch: refetchLatestContent
  } = useGetLatestConversationContent(
    id,
    callType === 'video'
      ? "KAZAM_SYSTEM_VIDEO_CALL"
      : "KAZAM_SYSTEM_VOICE_CALL")

  const audioRef = useRef<HTMLAudioElement>(null);

  const {data} = useGetConversationById(id);

  useEffect(() => {
    let intervalRef: NodeJS.Timeout;
    intervalRef = setInterval(async () => {
      await refetchLatestContent()
    }, 3000);

    return () => {
      intervalRef && clearInterval(intervalRef);
    };
  }, [id]);

  useEffect(() => {
    if (latestContent
      && latestContent.meta) {
      var meta = JSON.parse(latestContent.meta) as CallMeta
      if (meta
        && meta.status === 'ended'
          || meta.status === 'accepted'
          || meta.status === 'declined') {
            if (!contentId
            || (!!contentId
              && contentId === latestContent.conversationContentId)) {
              dismissDialog()
            }
      }
    }
  }, [latestContent]);

  const optionChosen = () => {
    audioRef.current?.pause();
    audioRef.current?.remove();
    dismissDialog();
  };

  const declineCall = () => {
    optionChosen();
  };

  const acceptCall = async () => {
    optionChosen();
    if (callType === 'video') {
      history.push(RoutePath.VIDEO_CALL_ROOM.replace(':id', id));
    } else {
      history.push(RoutePath.VOICE_CALL_ROOM.replace(':id', id));
    }

    if (latestContent) {
      let meta = JSON.stringify({
        status: 'accepted',
        duration: 0,
      } as CallMeta)
      await putContentDuration.mutateAsync({
        contentId: latestContent.conversationContentId,
        body: {
          meta
        }
      })
    }
  };

  useEffect(() => {
    if (data) {
      const receiver = data?.members.filter(
        member => member.userId !== authData?.user.userId,
      )[0].user;
      setReceiver(receiver);
    }
  }, [data]);

  useEffect(() => {
    audioRef.current?.play();
  }, [audioRef]);

  useEffect(() => {
    let timeoutRef: NodeJS.Timeout;
    timeoutRef = setTimeout(() => {
      dismissDialog();
    }, 60000);
    return () => {
      timeoutRef && clearTimeout(timeoutRef);
    };
  }, []);

  const putContentDuration = usePutConversationContent()
  const handleDeclinedCall = async () => {
    if (latestContent) {
      let meta = JSON.stringify({
        status: 'declined',
        duration: 0,
      } as CallMeta)
      await putContentDuration.mutateAsync({
        contentId: latestContent.conversationContentId,
        body: {
          meta
        }
      })
    }

    declineCall()
  }

  return (
    <StyledInAppCallNotifPage>
      <audio ref={audioRef} autoPlay loop>
        <source src={RingingTone} type="audio/wav"></source>
      </audio>
      <div className="call-nav">
        <CallNavbarComponent isVideoCall={false} isAnswered={false} />
      </div>

      <BetaSection />

      <div className="viewport overflow-hidden">
        <div
          className="w-full flex absolute z-20 flex-col items-center h-full pt-24"
          style={{
            marginTop: 56,
          }}>
          <div className="profile-picture relative">
            {receiver?.photo ? (
              <div className="avatar flex justify-center items-center">
                <img src={receiver?.photo} alt="profile" />
              </div>
            ) : (
              <div className="default-avatar flex justify-center items-center">
                <Icon name="user" color={colorTheme.white}></Icon>
              </div>
            )}

            <div className="online-status flex justify-center items-center">
              {receiver?.userId ? (
                <UserOnlineStatus
                  disabledUser={receiver.userStatus === 'disabled'}
                  bannedUser={receiver.isBanned}
                  userId={receiver.userId}
                  width={10}
                  height={10}
                />
              ) : (
                <StyleStatusIcon width={10} height={10} status={'online'} />
              )}
            </div>
          </div>

          <div className="overflow mt-3">
            <Typography
              label={receiver?.firstName || ''}
              variant="f4"
              weight="semibold"
              color={colorTheme.white}
              singleLine
            />
          </div>

          <div className="overflow mt-10">
            <Typography
              label={`Incoming ${callType} call...`}
              variant="f3"
              weight="normal"
              color={colorTheme.white}
              singleLine
            />
          </div>

          <div className="absolute top-2/4 z-50 flex w-full justify-between">
            <div
              className="flex flex-col items-center w-screen gap-y-4"
              onClick={handleDeclinedCall}>
              <div
                className="rounded-full p-4 cursor-pointer"
                style={{
                  backgroundColor: colorTheme.danger,
                }}>
                <Icon
                  name={
                    callType === 'video'
                      ? 'endVideo'
                      : 'declineVoice'
                  }
                  size={40}
                  type="button"
                  color={colorTheme.light}
                />
              </div>
              <Typography
                label="Decline"
                variant="f3"
                weight="normal"
                color={colorTheme.white}
                singleLine
              />
            </div>

            <div
              className="flex flex-col items-center w-screen gap-y-4"
              onClick={acceptCall}>
              <div
                className="rounded-full p-4 cursor-pointer"
                style={{
                  backgroundColor: colorTheme.green,
                }}>
                <Icon
                  name={callType === 'video' ? 'videoCall' : 'voiceCall'}
                  size={40}
                  type="button"
                  color={colorTheme.light}
                />
              </div>
              <Typography
                label="Accept"
                variant="f3"
                weight="normal"
                color={colorTheme.white}
                singleLine
              />
            </div>
          </div>
        </div>
      </div>
    </StyledInAppCallNotifPage>
  );
};

export default InAppCallNotificationView;
