import React, {useEffect, useState} from 'react';
import {Typography} from '../../../../core/components';
import AdminPage from '../../../../core/components/admin-page/AdminPage';
import {colorTheme} from '../../../../core/configs';
import {
  useGetAdminControls,
  useGetMatchingAttributes,
} from '../../../server/react-query';
import {
  AdminControl,
  MatchingAttributeResponse,
} from '../../../server/types/admin-control.types';
import {
  GenericChatMessageView,
  KawayControlView,
  KazamSettingsView,
  PaywallControllView,
  SearchWeightsView,
  AdsSettingsView,
  ReferralLinkView,
  SearchMatchView,
  SalaryLimitsView,
  VideoSettingsView,
} from '../../../views/admin-controls/index';
import SmsSettingsView from '../../../views/admin-controls/sms-settings/SmsSettings';

const AdminControlPage = () => {
  const [adminControlData, setAdminControlData] = useState<AdminControl[]>([]);
  const [matchingAttributesData, setMatchingAttributesData] = useState<
    MatchingAttributeResponse[]
  >([]);

  const {data: controlData, isFetching} = useGetAdminControls();
  const {data: matchingAttributes} = useGetMatchingAttributes();

  useEffect(() => {
    if (controlData) {
      setAdminControlData(controlData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlData]);

  useEffect(() => {
    if (matchingAttributes) {
      setMatchingAttributesData(matchingAttributes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchingAttributes]);

  return (
    <AdminPage showLoading={isFetching}>
      <div className="space-y-2">
        <Typography
          label="Controls"
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />

        <div className="space-y-6">
          <PaywallControllView data={adminControlData} />
          <AdsSettingsView data={adminControlData} />
          <KawayControlView data={adminControlData} />
          <KazamSettingsView data={adminControlData} />
          <SalaryLimitsView data={adminControlData} />
          <SmsSettingsView data={adminControlData} />
          <GenericChatMessageView data={adminControlData} />
          <ReferralLinkView data={adminControlData} />
          <VideoSettingsView data={adminControlData} />
          <SearchWeightsView data={matchingAttributesData} />
          <SearchMatchView data={adminControlData} />
        </div>
      </div>
    </AdminPage>
  );
};

export default AdminControlPage;
