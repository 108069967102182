import {yupResolver} from '@hookform/resolvers/yup';
import {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {
  RadioButton,
  RadioButtonFilled,
} from '../../../../core/assets/icons/components';
import {
  Button,
  DatePicker,
  Icon,
  ImageUpload,
  Input,
  Select,
  Toggle,
  Typography,
} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {postAdSchema} from '../../../schema/admin.schema';
import {usePostAd} from '../../../server/react-query';
import {KazamException, UploadPhotoReqBody} from '../../../server/types';
import {
  AdvertisementLinkType,
  AdvertisementTargetType,
  AdvertisementType,
  PostAdBody,
  inboundLinks,
} from '../../../server/types/admin-control.types';
import {StyledLabel, StyledTargetComponent, SyledContent} from './styles';
import { useToast } from '../../../../core/components/toast';

interface CreateAdModalViewProps {
  type: AdvertisementType;
  onClose: () => void;
  onCancel: () => void;
  onCreated: () => void;
  onError?: (message: string) => void;
}

const CreateAdModalView = (props: CreateAdModalViewProps) => {
  
  const [photo, setPhoto] = useState<UploadPhotoReqBody>({
    image: null,
  });
  const [selectedTarget, setSelectedTarget] = useState<AdvertisementTargetType>(
    'all',
  );
  const [selectedAdLinkType, setSelectedAdLinkType] = useState<AdvertisementLinkType>(
    'outbound'
  );
  const [isFeatured, setIsFeatured] = useState<boolean>(false);

  const postAd = usePostAd();

  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    setError,
    formState: {errors},
  } = useForm<PostAdBody>({
    resolver: yupResolver(postAdSchema),
    defaultValues: {
      name: '',
      outboundLink: '',
      startDate: '',
      endDate: '',
      type: props.type,
      targetType: selectedTarget,
      linkType: selectedAdLinkType,
      featured: false,
      includeInLoop: false
    },
  });

  const submitForm = async () => {
    handleSubmit(handleCreate)();
  };

  const handleCreate = async () => {
    try {
      await postAd.mutateAsync(getValues());
      props.onCreated();
    } catch (e: any) {
      const exception = e.data as KazamException;
      props?.onError && props.onError(exception ? exception.error : "Failed to create.")
    }
  };

  const onSelectPhoto = (file: any | null) => {
    if (file) {
      setPhoto({
        image: file,
      });

      setValue('image', file);
      setError('image', {});
    } else {
      setValue('image', undefined);
    }
  };

  const onImageError = (message: string) => {
    setError('image', {message: message, type: 'pattern'});
  };

  const onSelectTarget = (value: AdvertisementTargetType) => {
    setSelectedTarget(value);
    setValue('targetType', value);
  };

  const onSelectLinkType = (value: AdvertisementLinkType) => {
    setSelectedAdLinkType(value);
    setValue('linkType', value);
    setValue('outboundLink', "");
  };

  return (
    <div className="flex flex-col items-center px-4 pb-4 space-y-2">
      <div className="flex w-full justify-between items-center">
        <Typography
          label={
            props.type === 'splash' ? 'Create Splash Ad' : 'Create Banner Ad'
          }
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />
        <Icon
          name="x"
          color={colorTheme.dark}
          size={24}
          type="button"
          onClick={props.onClose}
          disabled={postAd.isLoading}
        />
      </div>

      <SyledContent>
        <div className="flex justify-center pb-2">
          <Controller
            control={control}
            name="image"
            render={({field: {value = '', onBlur, onChange}}) => (
              <ImageUpload
                label="Image"
                onSelectPhoto={onSelectPhoto}
                imageUploadSize={props.type === 'splash' ? 'splash' : 'banner'}
                error={errors.image?.message}
                onError={onImageError}
              />
            )}
          />
        </div>
        
        {props.type === 'splash' && 
          <div className='py-4 space-y-2'>
            <div className="w-full flex flex-row justify-between items-center">
              <div className="flex-col">
                <Typography
                  label="Featured"
                  variant="f2"
                  weight="normal"
                  color={colorTheme.dark}
                />

                <Typography
                  label="This will set the ad as featured."
                  variant="f1"
                  weight="normal"
                  color={colorTheme.darkFaded}
                />
              </div>
            
              <div>
                <Controller 
                  control={control}
                  name="featured"
                  render={({field: {value, onChange}}) => (
                    <Toggle
                      value={value}
                      onToggle={(v) => {
                        onChange(v)
                        setIsFeatured(v)
                      }} 
                    />
                  )}
                />
              </div>
            </div>
            
            {isFeatured && 
              <div className="w-full flex flex-row justify-between items-center">
                <div className="flex-col">
                  <Typography
                    label="Include in loop"
                    variant="f2"
                    weight="normal"
                    color={colorTheme.dark}
                  />

                  <Typography
                    label="This will set the ad to appear once or include in the loop."
                    variant="f1"
                    weight="normal"
                    color={colorTheme.darkFaded}
                  />
                </div>
                <div>
                  <Controller
                    control={control}
                    name="includeInLoop"
                    render={({field: {value, onChange}}) => (
                      <Toggle value={value} onToggle={onChange} />
                    )}
                  />
                </div>
              </div>
            }
          </div>
        }

        <StyledTargetComponent>
          <StyledLabel data-testid="label-element">Target</StyledLabel>
          <div className="flex space-x-4">
            <div
              className="cursor-pointer"
              style={{width: 'fit-content'}}
              onClick={() => onSelectLinkType('outbound')}>
              <div className="flex flex-row items-center">
                {selectedAdLinkType === 'outbound' ? (
                  <RadioButtonFilled
                    className="radio-button"
                    color={colorTheme.dark}
                  />
                ) : (
                  <RadioButton
                    className="radio-button"
                    color={colorTheme.dark}
                  />
                )}
                <Typography
                  label="Outbound"
                  variant="f2"
                  color={colorTheme.dark}
                />
              </div>
            </div>

            <div
              className="cursor-pointer"
              style={{width: 'fit-content'}}
              onClick={() => onSelectLinkType('inbound')}>
              <div className="flex flex-row items-center">
                {selectedAdLinkType === 'inbound' ? (
                  <RadioButtonFilled
                    className="radio-button"
                    color={colorTheme.dark}
                  />
                ) : (
                  <RadioButton
                    className="radio-button"
                    color={colorTheme.dark}
                  />
                )}
                <Typography
                  label="Inbound"
                  variant="f2"
                  color={colorTheme.dark}
                />
              </div>
            </div>
          </div>
        </StyledTargetComponent>

        <div className="space-y-2">
          <div>
            <Controller
              control={control}
              name="name"
              render={({field: {value = '', onBlur, onChange}}) => (
                <Input
                  label="Name"
                  inputSize="large"
                  placeholder="Enter ad name"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={errors.name?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              control={control}
              name="outboundLink"
              render={({field: {value = '', onBlur, onChange}}) => (
                selectedAdLinkType === 'outbound' ? (
                  <Input
                    label="Outbound Link"
                    inputSize="large"
                    placeholder="Enter outbound link"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={errors.outboundLink?.message}
                  />
                ) : (
                  <Select
                    label="Inbound link"
                    placeholder="Select inbound link"
                    items={inboundLinks[selectedTarget]}
                    itemRenderer={(item, selected) => (
                      <Typography
                        label={item}
                        variant="f1"
                        color={colorTheme.dark}
                        weight={selected ? 'semibold' : 'normal'}
                        className="flex-1"
                      />
                    )}
                    onChange={onChange}
                    value={value}
                    valueRenderer={value => value}
                    error={errors.outboundLink?.message}
                  />
                )
              )}
            />
          </div>

          <StyledTargetComponent>
            <StyledLabel data-testid="label-element">Target</StyledLabel>
            <div className="flex space-x-4">
              <div
                className="cursor-pointer"
                style={{width: 'fit-content'}}
                onClick={() => onSelectTarget('all')}>
                <div className="flex flex-row items-center">
                  {selectedTarget === 'all' ? (
                    <RadioButtonFilled
                      className="radio-button"
                      color={colorTheme.dark}
                    />
                  ) : (
                    <RadioButton
                      className="radio-button"
                      color={colorTheme.dark}
                    />
                  )}
                  <Typography
                    label="All"
                    variant="f2"
                    color={colorTheme.dark}
                  />
                </div>
              </div>

              <div
                className="cursor-pointer"
                style={{width: 'fit-content'}}
                onClick={() => onSelectTarget('kazam')}>
                <div className="flex flex-row items-center">
                  {selectedTarget === 'kazam' ? (
                    <RadioButtonFilled
                      className="radio-button"
                      color={colorTheme.dark}
                    />
                  ) : (
                    <RadioButton
                      className="radio-button"
                      color={colorTheme.dark}
                    />
                  )}
                  <Typography
                    label="Kasambahay"
                    variant="f2"
                    color={colorTheme.dark}
                  />
                </div>
              </div>

              <div
                className="cursor-pointer"
                style={{width: 'fit-content'}}
                onClick={() => onSelectTarget('homeowner')}>
                <div className="flex flex-row items-center">
                  {selectedTarget === 'homeowner' ? (
                    <RadioButtonFilled
                      className="radio-button"
                      color={colorTheme.dark}
                    />
                  ) : (
                    <RadioButton
                      className="radio-button"
                      color={colorTheme.dark}
                    />
                  )}
                  <Typography
                    label="Homeowner"
                    variant="f2"
                    color={colorTheme.dark}
                  />
                </div>
              </div>
            </div>
          </StyledTargetComponent>
          <div className="flex justify-between space-x-6">
            <div className="w-full">
              <Controller
                control={control}
                name="startDate"
                render={({field: {value, onBlur, onChange}}) => (
                  <DatePicker
                    label="Start date"
                    placeholder="Enter start date"
                    value={value}
                    onChange={onChange}
                    error={errors.startDate?.message}
                    enableFutureDates={true}
                  />
                )}
              />
            </div>
            <div className="w-full">
              <Controller
                control={control}
                name="endDate"
                render={({field: {value, onBlur, onChange}}) => (
                  <DatePicker
                    label="End date"
                    placeholder="Enter end date"
                    value={value}
                    onChange={onChange}
                    error={errors.endDate?.message}
                    enableFutureDates={true}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </SyledContent>
      <div className="w-full flex flex-row space-x-2 pt-4">
        <Button
          label="Cancel"
          variant="outlined"
          className="flex-1"
          onClick={props.onCancel}
          disabled={postAd.isLoading}
        />
        <div className="spacer" />
        <Button
          label="Create"
          color="primary"
          className="flex-1"
          onClick={submitForm}
          isLoading={postAd.isLoading}
        />
      </div>
    </div>
  );
};

export default CreateAdModalView;
