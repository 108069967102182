import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import CallNavbarComponent from './CallNavbarComponent';
import {useAuth} from '../../hooks/useAuth';
import {useHistory, useParams} from 'react-router-dom';
import {
  useGenerateCallJWT,
  useGetConversationById,
  useGetLatestConversationContent,
  usePutConversationContent,
} from '../../server/react-query';
import {UserResponse} from '../../server/types';
import {Icon, Typography} from '../../../core/components';
import {colorTheme} from '../../../core/configs';
import UserOnlineStatus from '../../components/online-status/UserOnlineStatus';
import StyleStatusIcon from '../../../core/components/profile-status/styled';
import CallActionsComponent from './CallActionsComponent';
import {PostCallSessionReqBody} from '../../server/types/call-session.types';
import ZoomContext from '../../contexts/zoom-context';
import ZoomVideo, {ConnectionState, MobileVideoFacingMode, Participant, Stream} from '@zoom/videosdk';
import ReceiverInfoComponent from './ReceiverInfoComponent';
import {StyledVideoCallRoomPage} from './styled';
import CallEndedActionComponent from './CallEndedActionComponent';
import { isAndroid, isBrowser, isDesktop, isMobile } from 'react-device-detect';
import CallTimer from './CallTimer';
import { CallMeta } from '../../server/types/conversation.types';
import BetaSection from './BetaSection';

interface VideoCallRoomPageParams {
  id: string;
}

export type MediaStream = typeof Stream;

const VideoCallRoomPage = () => {
  const {authData} = useAuth();
  const history = useHistory()

  const {id: conversationId} = useParams<VideoCallRoomPageParams>();
  const generateCallJWT = useGenerateCallJWT();
  const [stream, setStream] = useState<MediaStream>();
  const [jwt, setJwt] = useState<string>();
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [isStartedVideo, setIsStartedVideo] = useState<boolean>(false);
  const [isAnswered, setIsAnswered] = useState<boolean>(false);
  const [isDeclined, setIsDeclined] = useState<boolean>(false);
  const [hasRenderedLocalVideo, setHasRenderedLocalVideo] = useState<boolean>(false);
  const [isCallingAKazam, setIsCallingAKazam] = useState<boolean>(false);
  const [receiver, setReceiver] = useState<UserResponse>();
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [hideParticipant, setHideParticipant] = useState<boolean>(false);
  const [callStatus, setCallStatus] = useState<string>('Connecting...');
  const [callEnded, setCallEnded] = useState<boolean>(false);
  const [sessionName, setSessionName] = useState<string>('');
  const [receiverHasJoined, setReceiverHasJoined] = useState<boolean>(false);
  const [activeCamera, setActiveCamera] = useState<MobileVideoFacingMode>(
    MobileVideoFacingMode.User,
  );
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [canUseAudio, setCanUseAudio] = useState<boolean>(false);
  const [callDuration, setCallDuration] = useState<number>(0);

  const callerVideoElement = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const {data} = useGetConversationById(conversationId);
  const {
    data: latestContent,
    refetch: refetchLatestContent
  } = useGetLatestConversationContent(conversationId, "KAZAM_SYSTEM_VIDEO_CALL")
  const zoomClient = useContext(ZoomContext);

  useEffect(() => {
    const init = async () => {
      await zoomClient.init('en-US', 'Global', {
        patchJsMedia: true,
        leaveOnPageUnload: true,
        stayAwake: true,
        enforceMultipleVideos: true,
      });

      const sessionName = `Video call in ${conversationId}`
      setSessionName(sessionName)
      createSession(sessionName)
    };

    if (receiver) {
      init();
    }

    return () => {
      ZoomVideo.destroyClient();
    };
  }, [zoomClient, receiver]);

  const onConnectionChange = useCallback(
    (payload: any) => {
      if (payload.state === ConnectionState.Reconnecting) {
        const { reason, subsessionName } = payload;
        console.log("payload", reason);
      } else if (payload.state === ConnectionState.Connected) {
        setIsConnected(true)
        if (authData?.user?.userRole === 'homeowner'){
          setCallStatus('Calling...')
        }
        console.log('getSessionInfo', zoomClient.getSessionInfo());
      } else if (payload.state === ConnectionState.Closed) {
        if (payload.reason === 'ended by host') {
          console.log("ended by host");
        }
      }
    },
    [zoomClient]
  );

  const useParticipantsChange = (
    fn: (
      participants: Participant[],
      updatedParticipants?: Participant[],
    ) => void,
  ) => {
    const fnRef = useRef(fn);
    fnRef.current = fn;
    const callback = useCallback(
      (updatedParticipants?: Participant[]) => {
        console.log("useParticipantsChange", updatedParticipants);

        const participants = zoomClient.getAllUser();
        if (participants.length === 0) {
          if (isAnswered) endCall()
        } else if (participants.length === 1 && receiverHasJoined) {
          endCall();
        }
        fnRef.current?.(participants, updatedParticipants);
      },
      [zoomClient, receiverHasJoined],
    );

    useEffect(() => {
      zoomClient.on('connection-change', onConnectionChange);
      zoomClient.on('user-added', callback);
      zoomClient.on('user-removed', callback);
      zoomClient.on('user-updated', callback);
      return () => {
        zoomClient.off('connection-change', onConnectionChange);
        zoomClient.off('user-added', callback);
        zoomClient.off('user-removed', callback);
        zoomClient.off('user-updated', callback);
      };
    }, [zoomClient, callback, onConnectionChange]);
  };

  useEffect(() => {
    if (isAnswered) refetchLatestContent()
  }, [isAnswered]);

  useEffect(() => {
    let timeoutRef: NodeJS.Timeout;
    if (isConnected) {
      timeoutRef = setTimeout(() => {
        if (!isAnswered){
          endCall("No Answer...")
        }
      }, 60000);

      if ((isAnswered || isDeclined) && timeoutRef) {
        clearTimeout(timeoutRef);
      }
    }
    return () => {
      timeoutRef && clearTimeout(timeoutRef);
    };
  }, [isConnected, isAnswered, isDeclined]);

  useEffect(() => {
    let timeoutRef: NodeJS.Timeout;
    if (callEnded) {
      timeoutRef = setTimeout(() => {
        history.go(-1);
      }, 5000);
    }

    return () => {
      timeoutRef && clearTimeout(timeoutRef);
    };
  }, [callEnded]);

  useEffect(() => {
    let intervalRef: NodeJS.Timeout;
    intervalRef = setInterval(async () => {
      await refetchLatestContent()
    }, 3000);

    if (isAnswered && intervalRef) {
      clearInterval(intervalRef);
    }

    return () => {
      intervalRef && clearInterval(intervalRef);
    };
  }, [conversationId]);

  useEffect(() => {
    if (latestContent
      && latestContent.meta
      && hasRenderedLocalVideo) {
      var meta = JSON.parse(latestContent.meta) as CallMeta
      if (meta
        && meta.status === 'declined') {
        setIsDeclined(true)
        endCall("Call Declined...")
      }
    }
  }, [latestContent, hasRenderedLocalVideo]);

  useParticipantsChange(payload => {
    setParticipants(payload);
  });

  const createSession = async (sessionName: string) => {
    const data: PostCallSessionReqBody = {
      userId: authData?.user.userId || '',
      sessionName: sessionName,
      conversationId: conversationId,
    };

    const response = await generateCallJWT.mutateAsync(data);
    setJwt(response.jwt);
  };

  useEffect(() => {
    if (jwt && jwt.trim().length > 0) {
      const join = async () => {
        try {
          const userName = `${authData?.user.firstName} ${authData?.user.lastName}`;
          await zoomClient.join(sessionName, jwt, userName).catch(e => {
            console.log(e);
            endCall()
          });
          const stream = zoomClient.getMediaStream();
          await renderLocalVideo(stream);
          setHasRenderedLocalVideo(true)
        } catch (e: any) {
          console.log(e);
          if (e?.reason !== 'USER_FORBIDDEN_MICROPHONE'
            && e?.type !== 'VIDEO_USER_FORBIDDEN_CAPTURE'){
            endCall()
          }
        }
      };
      join();
    }
  }, [jwt, zoomClient]);

  const renderLocalVideo = async (stream: MediaStream) => {
    try {
      if (stream.isRenderSelfViewWithVideoElement()) {
        await stream.startAudio({originalSound: true});
        await stream.startVideo({
          videoElement: callerVideoElement.current as HTMLVideoElement,
          fullHd: true,
          originalRatio: isAndroid
        });
        setActiveCamera(MobileVideoFacingMode.User);
      } else {
        await stream.startVideo({
          originalRatio: isAndroid
        });
        stream.renderVideo(
          callerVideoElement.current as HTMLVideoElement,
          zoomClient.getCurrentUserInfo().userId,
          undefined,
          undefined,
          undefined,
          undefined,
          4,
        );
      }
      setStream(stream);
    } catch (error) {
      console.log("renderLocalVideo error", error);
    }
  };

  const renderRemoteVideo = (stream: MediaStream) => {
    participants.forEach(participant => {
      if (
        participant.userIdentity !== authData?.user.userId
      ) {
        setIsConnected(true)
        setIsAnswered(true);
        setReceiverHasJoined(true);

        if (participant.bVideoOn) {
          stream.renderVideo(
            canvasRef.current as HTMLCanvasElement,
            participant.userId,
            window.innerWidth,
            window.innerHeight,
            undefined,
            undefined,
            4,
          );
        }
      }
    });

    zoomClient.on('peer-video-state-change', payload => {
      console.log("er-video-state-change", payload);
      setHideParticipant(false)
      setReceiverHasJoined(true);
      if (payload.action === 'Start') {
        stream.renderVideo(
          canvasRef.current as HTMLCanvasElement,
          payload.userId,
          window.innerWidth,
          window.innerHeight,
          undefined,
          undefined,
          4,
        );
      } else if (payload.action === 'Stop') {
        setHideParticipant(true)
        setCallStatus("")
        stream.stopRenderVideo(
          canvasRef.current as HTMLCanvasElement,
          payload.userId,
        );
      }
    });
  };

  const putContentDuration = usePutConversationContent()
  const handleEndCall = async () => {
    endCall()
    if (latestContent) {
      let meta = JSON.stringify({
        status: 'ended',
        duration: callDuration
      } as CallMeta)
      await putContentDuration.mutateAsync({
        contentId: latestContent.conversationContentId,
        body: {
          meta
        }
      })
    }
  }

  const endCall = async (status?: string) => {
    if (stream) {
      stream.stopAudio();
      stream.stopVideo();
    }

    zoomClient.leave();
    // if (isCallingAKazam) {
    //   zoomClient.leave(true);
    // } else {
    //   zoomClient.leave();
    // }

    setCallEnded(true);
    setCallStatus(status ? status : 'Call Ended...');
  };

  const onMicClick = () => {
    if (stream) {
      if (!isMuted) {
        stream.muteAudio();
        setIsMuted(true);
      } else {
        stream.unmuteAudio();
        setIsMuted(false);
      }
    }
  };

  const onCameraClick = () => {
    if (stream) {
      if (!isHidden) {
        stream.stopVideo();
        setIsHidden(true);
      } else {
        renderLocalVideo(zoomClient.getMediaStream());
        setIsHidden(false);
      }
    }
  };

  const onSwitchCamClick = async () => {
    if (stream) {
      let facingMode: MobileVideoFacingMode;
      if (activeCamera === MobileVideoFacingMode.User) {
        facingMode = MobileVideoFacingMode.Environment;
      } else {
        facingMode = MobileVideoFacingMode.User;
      }
      await stream.switchCamera(facingMode);
      setActiveCamera(facingMode);
    }
  };

  useEffect(() => {
    console.log("data", data);
    if (data) {
      const receiver = data?.members.filter(
        member => member.userId !== authData?.user.userId,
      )[0].user;
      setReceiver(receiver);
    }
  }, [data]);

  useEffect(() => {
    console.log("participants", participants);

    if (participants && participants.length > 0) {
      renderRemoteVideo(zoomClient.getMediaStream());

      var me = participants.find(p => p.userIdentity === authData?.user?.userId)
      if (me) {
        setCanUseAudio(!!me.audio)

        if (isMobile) {
          if (hasRenderedLocalVideo
            && !me.bVideoOn) {
            if (isCallingAKazam) {
              setIsHidden(true)
            } else {
              endCall()
            }
          }
        }
      }
    }
  }, [participants]);

  useEffect(() => {
    if (receiver) {
      const receiverIsAKazam = receiver?.userRole === 'kazam' ? true : false;
      setIsCallingAKazam(receiverIsAKazam);
      // setCallStatus(receiverIsAKazam ? 'Calling...' : 'Connecting...');
      // const sessionName = `Video call in ${conversationId}`;
      // setSessionName(sessionName);
      // createSession(sessionName);
    }
  }, [receiver]);

  return (
    <StyledVideoCallRoomPage
      style={{ background: (isAnswered && !callEnded)
        ? 'rgba(0,0,0,0.3)'
        : 'linear-gradient(180deg,rgba(99, 0, 153, 0.74) 0%,rgba(15, 44, 197, 0.73) 100%)'}}
    >
      <div>
        <div className="call-nav">
          <CallNavbarComponent isVideoCall isAnswered={(isAnswered && !callEnded)} />
        </div>

        <BetaSection />
      </div>

      <div className="viewport overflow-hidden">
        {(!isAnswered || hideParticipant || callEnded) && (
          <div
            className="w-full flex absolute z-20 flex-col items-center h-full pt-24"
            style={{
              marginTop: 56,
            }}>
            <div className="profile-picture relative">
              {receiver?.photo ? (
                <div className="avatar flex justify-center items-center">
                  <img src={receiver?.photo} alt="profile" />
                </div>
              ) : (
                <div className="default-avatar flex justify-center items-center">
                  <Icon name="user" color={colorTheme.white}></Icon>
                </div>
              )}

              <div className="online-status flex justify-center items-center">
                {receiver?.userId ? (
                  <UserOnlineStatus
                    disabledUser={receiver.userStatus === 'disabled'}
                    bannedUser={receiver.isBanned}
                    userId={receiver.userId}
                    width={10}
                    height={10}
                  />
                ) : (
                  <StyleStatusIcon width={10} height={10} status={'online'} />
                )}
              </div>
            </div>

            <div className="overflow mt-3">
              <Typography
                label={receiver?.firstName || ''}
                variant="f4"
                weight="semibold"
                // color={isStartedVideo ? colorTheme.white : colorTheme.black}
                color={colorTheme.white}
                singleLine
              />
            </div>

            <div className="overflow mt-10">
              <Typography
                label={callStatus}
                variant="f3"
                weight="normal"
                // color={isStartedVideo ? colorTheme.white : colorTheme.black}
                color={colorTheme.white}
                singleLine
              />
            </div>
          </div>
        )}
        {!callEnded && (
          <div className="video-container flex justify-center">
            <canvas
              className="video-canvas"
              ref={canvasRef}
              width={window.innerWidth}
              height={window.innerHeight}
            />
            <div className="preview-video">
              <video
                className={`object-cover preview-video-show ${
                  (isAnswered && !callEnded) && 'self-video-min'
                } ${
                  activeCamera ===  MobileVideoFacingMode.Environment &&
                  'phone-rear-camera-view'
                }`}
                style={{
                  display: isAndroid
                    && (!hasRenderedLocalVideo || isHidden) ? 'none' : 'block'
                }}
                playsInline
                ref={callerVideoElement}
                onLoadedData={() => setIsStartedVideo(true)}
              />
            </div>
          </div>
        )}

        {isAnswered && !callEnded &&
          <div className="mt-4">
            <CallTimer hide onUpdateTotalSeconds={setCallDuration}/>
          </div>
        }
        <div>
          {(!callEnded && receiver?.userRole !== 'admin') && (
            <div className="flex flex-col gap-x-2 absolute bottom-16 z-50 ml-6">
              <ReceiverInfoComponent
                receiver={receiver as UserResponse}
                isStartedVideo={isStartedVideo}
                isVideoCall={true}
              />
            </div>
          )}
          {callEnded ? (
            <div className="absolute top-2/4 z-50">
              <CallEndedActionComponent />
            </div>
          ) : (
            <div className="video-operations video-operations-preview absolute bottom-0 z-50 w-full">
              <div className='flex justify-center w-full'>
                <CallActionsComponent
                  isConnected={isConnected}
                  hasRenderedLocalVideo={hasRenderedLocalVideo}
                  canUseAudio={canUseAudio}
                  disabledAudio={isMuted}
                  disabledVideo={isHidden}
                  isVideoCall={true}
                  isKazam={!isCallingAKazam}
                  onMicClick={onMicClick}
                  onCameraClick={onCameraClick}
                  onEndCallClick={handleEndCall}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </StyledVideoCallRoomPage>
  );
};

export default VideoCallRoomPage;
