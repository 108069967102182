import React, {FormEvent, useEffect, useState} from "react";
import AdminPage from "../../../../core/components/admin-page/AdminPage";
import {StyledReferralViewPage} from "./styled";
import {Button, Icon, Input, Typography} from "../../../../core/components";
import {colorTheme} from "../../../../core/configs";
import {UserResponse} from "../../../server/types";
import {useGetReferralsByUser, useGetUserById, usePostJob} from "../../../server/react-query";
import {useHistory, useParams} from "react-router-dom";
import AdminTable from "../users/AdminTable";
import {Column} from "react-table";
import {format} from "date-fns";
import ExtractReportDialog from "../../../views/dialog-content/ExtractReportDialog";
import {useToast} from "../../../../core/components/toast";
import {useDialog} from "../../../../core/components/dialog";
import {useAuth} from "../../../hooks/useAuth";

interface AdminReferralViewPageParams {
  id: string;
}

interface TableData {
  userId: string;
  name: string;
  location: string;
  date: string;
  userType: string;
}

const AdminReferralViewPage = () => {
  const [user, setUser] = useState<UserResponse | undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const history = useHistory();
  const {id: userId} = useParams<AdminReferralViewPageParams>();
  const {data: hookDataUser} = useGetUserById(userId);
  const postJob = usePostJob();
  const {presentToast} = useToast();
  const {presentDialog, dismissDialog} = useDialog();
  const {authData} = useAuth();

  const [columns] = useState<Column<TableData>[]>([
    {
      Header: 'Name',
      accessor: 'name', // accessor is the "key" in the data
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'User Type',
      accessor: 'userType',
    },
  ] as Column<TableData>[]);
  const [data, setData] = useState<TableData[]>([]);
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const {data: hookDataReferralsByUser, refetch} = useGetReferralsByUser(userId, pageNumber, searchQuery);

  useEffect(() => {
    (async () => {
      await refetch();
    })();
  }, [pageNumber]);

  useEffect(() => {
    if (hookDataReferralsByUser) {
      const response = hookDataReferralsByUser;
      setPageCount(Math.ceil(response.total / response.pageSize));
      setData(response.data.map(i => {
        const userId = i.referredUser.userId;

        const name = `${i.referredUser.firstName} ${i.referredUser.lastName}`;

        let location = '';
        if (i.referredUser.filterSetting) {
          const userLocation = i.referredUser.filterSetting.location;
          if (userLocation) {
            location = userLocation?.cityOrMunicipality;
            if (userLocation.hasProvince) {
              location += `, ${userLocation.province.name}`;
            }
          }
        }

        const date = format(new Date(i.createdOn), 'M/dd/yyyy');

        const userType = i.referredUser.userRole === "homeowner" ? 'Homeowner' : 'Kasambahay';

        return {userId, name, location, date, userType} as TableData;
      }));
    }
  }, [hookDataReferralsByUser]);

  useEffect(() => {
    if (hookDataUser) {
      setUser(hookDataUser);
    }
  }, [hookDataUser]);

  const onBackButtonClickedHandler = () => {
    history.go(-1);
  };

  const manualRenderFn = (columnId: string, rowData: TableData) => {
    if (columnId === 'action') {
      return undefined;
    } else if (columnId === 'open') {
      return (
        <div className="flex flex-col justify-center items-end">
          <Icon name="chevronRight" color={colorTheme.dark} />
        </div>
      );
    } else {
      return (<div />);
    }
  };

  const onPageChangedHandler = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const onSearchInputChangedHandler = (event: FormEvent<HTMLInputElement>) => {
    setSearchQuery(event.currentTarget.value);

    if (!event.currentTarget.value) {
      setTimeout(async () => {
        await refetch();
      }, 300);
    }
  };

  const onSearchClickedHandler = async () => {
    await refetch();
  };

  const onClearSearchClickedHandler = () => {
    setSearchQuery('');
    setTimeout( async () => await refetch(), 300);
  };

  const onExtractClickedHandler = async () => {
    if (authData?.user.email) {
      try {
        await postJob.mutateAsync({
          type: 'referralsByUserReport',
          params: JSON.stringify({
            email: authData?.user.email,
            userId: userId
          }),
        });

        presentDialog({
          headerText: '',
          content: (
            <ExtractReportDialog requestName="User's Referrals" onOkayClick={() => dismissDialog()} />
          ),
          enableBackdropDismiss: true,
          hideClose: true,
        });
      } catch (error) {
        console.log({error});
        presentToast({
          message: 'Request failed',
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  return (
    <AdminPage>
      <StyledReferralViewPage>
        <div
          className="w-full flex flex-row items-center cursor-pointer"
          onClick={onBackButtonClickedHandler}>
          <div className="back-btn-container">
            <Icon
              name="chevronLeft"
              size={24}
              color={colorTheme.dark}
              type="button" />
          </div>
          <Typography
            label={`Referrals by ${user?.firstName} ${user?.lastName}`}
            variant="f3"
            weight="semibold"
            color={colorTheme.dark}
          />
        </div>

        <div className="search-container flex flex-row w-full items-center">
          <div className="flex flex-col w-full">
            <Input
              inputSize="large"
              placeholder="Search referrals"
              onChange={onSearchInputChangedHandler}
              value={searchQuery}
              iconRight={searchQuery ? (
                <Icon
                  type="button"
                  onClick={onClearSearchClickedHandler}
                  color={colorTheme.dark}
                  name="x"
                  size={12} />
              ) : undefined}
              iconLeft={(
                <Icon
                  name="search"
                  size={12}
                  color={colorTheme.dark} />
              )} />
          </div>
          <div className="flex flex-row">
            <Button
              label="Search"
              color="primary"
              className="btn-search"
              onClick={onSearchClickedHandler} />
          </div>
          <div className="flex flex-row flex-shrink-0">
            <Button
              label="Extract All"
              variant="outlined"
              className="btn-search"
              onClick={onExtractClickedHandler} />
          </div>
        </div>

        <div className="table-container">
          <AdminTable
            columns={columns}
            data={data}
            pageSize={10}
            pageCount={pageCount}
            showDebugView={false}
            onPageChange={onPageChangedHandler}
            manualRenderFn={manualRenderFn} />
        </div>

      </StyledReferralViewPage>
    </AdminPage>
  );
};

export default AdminReferralViewPage;
