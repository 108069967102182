import React, {useEffect, useState} from 'react';
import {Icon, Typography} from '../../../../core/components';
import AdminPage from '../../../../core/components/admin-page/AdminPage';
import {useDialog} from '../../../../core/components/dialog';
import {colorTheme} from '../../../../core/configs';
import CreateAdModalView from '../../../views/dialog-content/ads-modal/CreateAdModalView';
import UpdateAdModalView from '../../../views/dialog-content/ads-modal/UpdateAdModalView';
import AdminAdsTabs, {AdTab} from './AdminAdsTabs';
import AdminAdsSearch from './AdminAdsSearch';
import {
  AdvertisementResponse,
  AdvertisementTargetType,
  AdvertisementType,
} from '../../../server/types/admin-control.types';
import {useDeleteAd, useGetAds, usePostJob} from '../../../server/react-query';
import {Column} from 'react-table';
import AdminTable from '../users/AdminTable';
import {format} from 'date-fns';
import {capitalize} from '../../../utils/random.util';
import GenericConfirmationDialog from '../../../views/dialog-content/generic-dialog/GenericConfirmationDialog';
import ExtractReportDialog from "../../../views/dialog-content/ExtractReportDialog";
import {useToast} from "../../../../core/components/toast";
import {useAuth} from "../../../hooks/useAuth";

interface TableData {
  advertisementId: string;
  name: string;
  outboundLink: string;
  imageUrl: string;
  type: AdvertisementType;
  targetType: string;
  status: string;
  impressions: number;
  createdOn: string;
  featured: string;
}

const AdminAdsPage = () => {
  const {presentDialog, dismissDialog} = useDialog();
  const [activeTab, setActiveTab] = useState<AdTab>(AdTab.SPLASH);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchKey, setSearchKey] = useState<string>('');
  const [adsData, setAdsData] = useState<TableData[]>([]);

  const {data, isLoading, isFetching, refetch} = useGetAds({
    searchKey: searchKey,
    pageNumber: pageNumber,
    type: activeTab === AdTab.SPLASH ? 'splash' : 'banner',
  });

  const postJob = usePostJob();
  const {presentToast} = useToast();
  const {authData} = useAuth();

  const [columns] = useState<Column<TableData>[]>([
    {
      Header: 'Name',
      accessor: 'name', // accessor is the "key" in the data
    },
    {
      Header: 'Status',
      accessor: 'status', // accessor is the "key" in the data
    },
    {
      Header: 'Featured',
      accessor: 'featured'
    },
    {
      Header: 'Target',
      accessor: 'targetType', // accessor is the "key" in the data
    },
    {
      Header: 'Published Date',
      accessor: 'createdOn',
    },
    {
      Header: 'Impressions',
      accessor: 'impressions', // accessor is the "key" in the data
    },
    {
      Header: 'Action',
      accessor: 'action',
    }
  ] as Column<TableData>[]);

  useEffect(() => {
    if (data) {
      setPageCount(data.totalPages);
      setPageNumber(data.pageNumber);

      setAdsData(
        data.data.map(i => {
          const status = capitalize(i.status);
          const targetType = i.targetType == 'homeowner' ? "Homeowner" : "Kasambahay"  ;
          const createdOn = format(new Date(i.createdOn), 'M/dd/yyyy');
          const featured = i.featured ? "Featured" : "No"

          return {...i, status, targetType, createdOn, featured} as TableData;
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, pageNumber]);

  useEffect(() => {
    if (searchKey === '') {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  const createAd = () => {
    presentDialog({
      headerText: '',
      content: (
        <CreateAdModalView
          type={activeTab as AdvertisementType}
          onClose={dismissDialog}
          onCancel={dismissDialog}
          onCreated={() => {
            dismissDialog();
            refetch();
          }}
          onError={showError}
        />
      ),
      enableBackdropDismiss: false,
      hideClose: true,
      fullWidth: true,
    });
  };

  const viewAd = (adId: string) => {
    presentDialog({
      headerText: '',
      content: (
        <UpdateAdModalView
          adId={adId}
          onClose={dismissDialog}
          onCancel={dismissDialog}
          onUpdated={() => {
            dismissDialog();
            refetch();
          }}
          onError={showError}
        />
      ),
      enableBackdropDismiss: false,
      hideClose: true,
      fullWidth: true,
    });
  };

  const showError = (message: string) => {
    presentToast({
      message: message,
      variant: 'error',
      position: 'bottom',
    })
  }

  const onPageChangedHandler = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const onSelectedTabChanged = (tab: AdTab) => {
    setActiveTab(tab);
  };

  const onSearch = () => {
    refetch();
  };

  const onAdClick = (value: any) => {
    const data = value as AdvertisementResponse;
    if (data) {
      viewAd(data.advertisementId);
    }
  };

  const onDeleteAdClick = (value: any) => {
    const data = value as AdvertisementResponse;
    deleteAddDialog(data);
  };

  const deleteAd = useDeleteAd();
  const deleteAsync = async (data: AdvertisementResponse) => {
    if (data) {
      try {
        await deleteAd.mutateAsync(data.advertisementId);
        dismissDialog();
      } catch (e: any) {
        console.log(e);
      }
    }
  };
  const deleteAddDialog = (data: AdvertisementResponse) => {
    presentDialog({
      headerText: '',
      content: (
        <GenericConfirmationDialog
          headerText="Delete Ad"
          desc="Are you sure you want to delete this ad?"
          rightButtonText="Delete"
          leftButtonText="Cancel"
          onLeftButtonAction={dismissDialog}
          onRightButtonAction={() => {
            deleteAsync(data);
          }}
        />
      ),
      enableBackdropDismiss: true,
    });
  };

  const manualRenderFn = (columnId: string, rowData: TableData) => {
    return (
      <div
        className="cursor-pointer"
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation();
          onDeleteAdClick(rowData);
        }}>
        <Typography
          label="Delete"
          variant="f2"
          color={colorTheme.primary}
          align="left"
        />
      </div>
    );
  };

  const onExtractClickedHandler = async () => {
    if (authData?.user.email) {
      try {
        await postJob.mutateAsync({
          type: 'advertisementReport',
          params: JSON.stringify({
            email: authData?.user.email
          }),
        });

        presentDialog({
          headerText: '',
          content: (
            <ExtractReportDialog requestName="Advertisement" onOkayClick={() => dismissDialog()} />
          ),
          enableBackdropDismiss: true,
          hideClose: true,
        });
      } catch (error) {
        console.log({error});
        presentToast({
          message: 'Request failed',
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  return (
    <AdminPage>
      <div>
        <Typography
          label="Manage Ads"
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />
      </div>

      <AdminAdsTabs selected={activeTab} onChange={onSelectedTabChanged} />

      <AdminAdsSearch
        value={searchKey}
        onClickCreate={createAd}
        onExtractAllClickedHandler={onExtractClickedHandler}
        onChange={setSearchKey}
        onClickSearch={onSearch}
      />

      <div className="pt-6">
        <AdminTable
          columns={columns}
          data={adsData}
          pageSize={10}
          pageCount={pageCount}
          showDebugView={false}
          onPageChange={onPageChangedHandler}
          onRowClick={onAdClick}
          manualRenderFn={manualRenderFn}
        />
      </div>
    </AdminPage>
  );
};

export default AdminAdsPage;
