import React, {FormEvent, useEffect, useState} from 'react';
import {Button, Icon, Input, Typography} from '../../../../core/components';
import AdminPage from '../../../../core/components/admin-page/AdminPage';
import {colorTheme} from '../../../../core/configs';
import {StyledAdminReferralsPage} from "./styled";
import {Column} from "react-table";
import {
  useGetReferralStatistics,
  useGetReferralSummary,
  useGetReferralTransactions, usePostJob
} from "../../../server/react-query";
import AdminTable from "../users/AdminTable";
import {GetReferralStatisticsResponse, GetReferralTransactionsResponse} from "../../../server/types";
import ReferralLineChart from "./ReferralLineChart";
import {useHistory} from "react-router-dom";
import {RoutePath} from "../../../navigation/config/RouteConfig";
import ExtractReportDialog from "../../../views/dialog-content/ExtractReportDialog";
import {useToast} from "../../../../core/components/toast";
import {useDialog} from "../../../../core/components/dialog";
import {useAuth} from "../../../hooks/useAuth";
import AdminReferralTabs, { ReferralType } from './AdminReferralTabs';

interface TableData {
  id: string;
  name: string;
  code: string;
  location: string;
  referrals: number;
  userType: string;
  type: string;
}

type GraphTabIndex = 0 | 1 | 2;

const AdminReferralsPage = () => {
  const defaultSelectedTab = 1;

  const [activeTab, setActiveTab] = useState<ReferralType>(ReferralType.USER);
  const [graphTabIndex, setGraphTabIndex] = useState<GraphTabIndex>(defaultSelectedTab);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [data, setData] = useState<TableData[]>([]);
  const [referralStatistics, setReferralStatistics] = useState<GetReferralStatisticsResponse>({
    referrals: {
      lastMonthTotal: 0,
      overallTotal: 0,
      last30DaysNewReferrals: 0
    },
    referrers: {
      lastMonthTotal: 0,
      overallTotal: 0,
      last30DaysNewReferrers: 0
    }
  });
  const [referralTransactions, setReferralTransactions] = useState<GetReferralTransactionsResponse | undefined>(undefined);
  const [referralsGain, setReferralsGain] = useState<number>(0);
  const [referrersGain, setReferrersGain] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    const {referrals, referrers} = referralStatistics;

    if (referrals.lastMonthTotal > 0) {
      setReferralsGain((referrals.last30DaysNewReferrals / referrals.overallTotal) * 100);
    } else {
      setReferralsGain(referrals.lastMonthTotal);
    }

    if (referrers.lastMonthTotal > 0) {
      setReferrersGain((referrers.last30DaysNewReferrers / referrers.overallTotal) * 100);
    } else {
      setReferrersGain(referrers.lastMonthTotal);
    }
  }, [referralStatistics]);

  const [columns] = useState<Column<TableData>[]>([
    {
      Header: 'Name',
      accessor: 'name', // accessor is the "key" in the data
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Referrals',
      accessor: 'referrals',
    },
    {
      Header: 'User Type',
      accessor: 'userType',
    },
    {
      Header: '',
      accessor: 'action',
    },
    {
      Header: '',
      accessor: 'open',
    },
  ] as Column<TableData>[]);

  const {
    data: hookDataReferralSummaries,
    refetch: refetchReferralSummaries,
    isFetching: fetchingSummary
  } = useGetReferralSummary(pageNumber, searchQuery, activeTab);

  const {
    data: hookDataReferralStatistics,
    refetch: refetchStatistics,
    isFetching: fetchingStatistics} = useGetReferralStatistics(activeTab);

  const {
    data: hookDataReferralTransactions,
    refetch: refetchReferralTransactions,
    isFetching: fetchingtransactions
  } = useGetReferralTransactions(graphTabIndex === 0 ? 2 : graphTabIndex === 1 ? 1 : 0, activeTab);

  const history = useHistory();
  const postJob = usePostJob();
  const {presentToast} = useToast();
  const {presentDialog, dismissDialog} = useDialog();
  const {authData} = useAuth();

  useEffect(() => {
    var referralListPageNumber = localStorage.getItem('referralListPageNumber');
    if (referralListPageNumber) {
      setPageNumber(+referralListPageNumber);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await refetchReferralSummaries();
    })();
  }, [pageNumber]);

  useEffect(() => {
    (async () => {
      await refetchReferralTransactions();
    })();
  }, [graphTabIndex]);

  useEffect(() => {
    if (hookDataReferralTransactions) {
      setReferralTransactions(hookDataReferralTransactions);
    }
  }, [hookDataReferralTransactions]);

  useEffect(() => {
    if (hookDataReferralStatistics) {
      setReferralStatistics(hookDataReferralStatistics);
    }
  }, [hookDataReferralStatistics]);

  useEffect(() => {
    if (hookDataReferralSummaries && hookDataReferralSummaries.pageNumber === pageNumber) {
      const response = hookDataReferralSummaries;
      setPageCount(Math.ceil(response.total / response.pageSize));
      setData(response.data.map(i => {
        let id = "";
        let name = "";
        let code = "";
        let location = "";
        let userType = "";
        let type = "";
        const referrals = i.referralCount;

        if (i.referralLink) {
          id = i.referralLink.referralLinkId;
          name = i.referralLink.name;
          code = i.referralLink.code;
          type = i.referralLink.type;

        } else {
          id = i.user.userId;

          name = `${i.user.firstName} ${i.user.lastName}`;
          if (i.user.filterSetting) {
            const userLocation = i.user.filterSetting.location;
            if (userLocation) {
              location = userLocation?.cityOrMunicipality;
              if (userLocation.hasProvince) {
                location += `, ${userLocation.province.name}`;
              }
            }
          }
  
          userType = i.user.userRole === "homeowner" ? 'Homeowner' : 'Kasambahay';
        }

        return {id, name, code, location, referrals, userType, type} as TableData;
      }));
    }
  }, [hookDataReferralSummaries]);

  const onGraphTabClickHandler = (tabIndex: GraphTabIndex) => {
    setGraphTabIndex(tabIndex);
  };

  const onPageChangedHandler = (pNum: number) => {
    if (pNum !== pageNumber) {
      setPageNumber(pNum);
      localStorage.setItem('referralListPageNumber', pNum.toString());
    }
  };

  const manualRenderFn = (columnId: string, rowData: TableData) => {
    if (columnId === 'action') {
      return undefined;
    } else if (columnId === 'open') {
      return (
        <div className="flex flex-col justify-center items-end">
          <Icon name="chevronRight" color={colorTheme.dark} />
        </div>
      );
    } else {
      return (<div />);
    }
  };

  const tableRowClickHandler = (rowData: TableData) => {
    let url = RoutePath.ADMIN_REFERRALS_VIEW.toString();
    url = url.replace(':id', rowData.id);
    history.push(url);
  };

  const onSearchInputChangedHandler = (event: FormEvent<HTMLInputElement>) => {
    setSearchQuery(event.currentTarget.value);

    if (!event.currentTarget.value) {
      setTimeout(async () => {
        await refetchReferralSummaries();
      }, 300);
    }
  };

  const onExtractClickedHandler = async () => {
    if (authData?.user.email) {
      try {
        await postJob.mutateAsync({
          type: 'referralsReport',
          params: JSON.stringify({
            email: authData?.user.email,
          }),
        });

        presentDialog({
          headerText: '',
          content: (
            <ExtractReportDialog requestName="Referrals" onOkayClick={() => dismissDialog()} />
          ),
          enableBackdropDismiss: true,
          hideClose: true,
        });
      } catch (error) {
        console.log({error});
        presentToast({
          message: 'Request failed',
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  const onSearchClickedHandler = async () => {
    await refetchReferralSummaries();
  };

  const onClearSearchClickedHandler = () => {
    setSearchQuery('');
    setTimeout( async () => await refetchReferralSummaries(), 300);
  };

  const onSelectedTabChanged = async (tab: ReferralType) => {
    if (tab !== activeTab) {
      var url = (tab === ReferralType.USER)
      ? RoutePath.ADMIN_REFERRALS_BY_USER
      : RoutePath.ADMIN_GENERATED_REFERRALS;
      
      localStorage.setItem('referralListPageNumber', '1');
      history.replace(url);
    }
  };

  return (
    <AdminPage showLoading={
      fetchingStatistics
      || fetchingSummary
      || fetchingtransactions}>
      <StyledAdminReferralsPage>
        <Typography
          label="Referrals"
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />

        <AdminReferralTabs selected={activeTab} onChange={onSelectedTabChanged} />

        <div className="w-full flex flex-row dashboard">
          <div className="flex flex-col col-1">
            <div className="dashboard-item total-referrals">
              <Typography label="TOTAL REFERRALS" variant="f1" weight="semibold" />
              <Typography label={referralStatistics.referrals.overallTotal} variant="f6" weight="semibold" />
              <div className="flex flex-row w-full items-center">
                <Icon
                  name={referralsGain >= 0 ? "bxUpArrow" : "bxDownArrow"}
                  size={8}
                  color={referralsGain >= 0 ? colorTheme.green : colorTheme.red} />
                <Typography
                  label={referralStatistics.referrals.last30DaysNewReferrals}
                  variant="f1"
                  color={referralsGain >= 0 ? colorTheme.green : colorTheme.red} />
                <Typography
                  label={` (${referralsGain > 0 ? '+' : ''}${referralsGain.toFixed(1)}%) over the last 30 days`}
                  variant="f1" />
              </div>
            </div>

            <div className="dashboard-item active-referrers">
              <Typography label="ACTIVE REFERRERS" variant="f1" weight="semibold" />
              <Typography label={referralStatistics.referrers.overallTotal} variant="f6" weight="semibold" />
              <div className="flex flex-row w-full items-center">
                <Icon
                  name={referrersGain >= 0 ? "bxUpArrow" : "bxDownArrow"}
                  size={8}
                  color={referrersGain >= 0 ? colorTheme.green : colorTheme.red} />
                <Typography
                  label={referralStatistics.referrers.last30DaysNewReferrers}
                  variant="f1"
                  color={referrersGain >= 0 ? colorTheme.green : colorTheme.red} />
                <Typography
                  label={` (${referrersGain > 0 ? '+' : ''}${referrersGain.toFixed(1)}%) over the last 30 days`}
                  variant="f1" />
              </div>
            </div>
          </div>

          <div className="flex flex-col col-2 dashboard-item">
            <div className="graph-tabs flex flex-row">
              <div
                className={['graph-tab', graphTabIndex === 0 ? 'selected' : ''].join(' ')}
                onClick={() => onGraphTabClickHandler(0)}>
                <Typography
                  label="Yearly"
                  variant="f1"
                  color={graphTabIndex === 0 ? colorTheme.white : colorTheme.dark} />
              </div>
              <div
                className={['graph-tab', graphTabIndex === 1 ? 'selected' : ''].join(' ')}
                onClick={() => onGraphTabClickHandler(1)}>
                <Typography
                  label="Monthly"
                  variant="f1"
                  color={graphTabIndex === 1 ? colorTheme.white : colorTheme.dark} />
              </div>
              <div
                className={['graph-tab', graphTabIndex === 2 ? 'selected' : ''].join(' ')}
                onClick={() => onGraphTabClickHandler(2)}>
                <Typography
                  label="Weekly"
                  variant="f1"
                  color={graphTabIndex === 2 ? colorTheme.white : colorTheme.dark} />
              </div>
            </div>
            <div className="graph-container flex flex-col justify-center">
              <ReferralLineChart data={referralTransactions} />
            </div>
          </div>

          {/*<div className="flex flex-col col-3 dashboard-item chart-doughnut flex flex-col justify-center">*/}
            {/*<ReferralDoughnutChart*/}
            {/*  successfulCount={referralStatistics.referrals.successful}*/}
            {/*  unsuccessfulCount={referralStatistics.referrals.unsuccessful} />*/}
          {/*</div>*/}
        </div>

        <div className="search-container flex flex-row w-full items-center">
          <div className="flex flex-col w-full">
            <Input
              inputSize="large"
              onChange={onSearchInputChangedHandler}
              value={searchQuery}
              iconRight={searchQuery ? (
                <Icon
                  type="button"
                  onClick={onClearSearchClickedHandler}
                  color={colorTheme.dark}
                  name="x"
                  size={12} />
              ) : undefined}
              placeholder="Search referrers"
              iconLeft={(<Icon name="search" size={12} color={colorTheme.dark} />)} />
          </div>
          <div className="flex flex-row">
            <Button
              label="Search"
              color="primary"
              className="btn-search"
              onClick={onSearchClickedHandler} />
          </div>
          <div className="flex flex-row flex-shrink-0">
            <Button
              label="Extract All"
              variant="outlined"
              className="btn-search"
              onClick={onExtractClickedHandler} />
          </div>
        </div>

        <div className="table-container">
          <AdminTable
            columns={columns}
            data={data}
            pageSize={10}
            pageNumber={pageNumber}
            pageCount={pageCount}
            showDebugView={false}
            onPageChange={onPageChangedHandler}
            rowClassName="table-row"
            onRowClick={tableRowClickHandler}
            manualRenderFn={manualRenderFn} />
        </div>
      </StyledAdminReferralsPage>
    </AdminPage>
  );
};

export default AdminReferralsPage;
