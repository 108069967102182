import {
  StyledProfileView,
  StyledImage,
  StyledSideDrawerProfile,
} from './styled';
import {Icon, Typography} from '..';
import {colorTheme} from '../../configs';
import { localization } from '../../../app/localization/Localization';

interface SideDrawerProfileProps {
  image?: string;
  firstName: string;
  lastName: string;
  onProfileClick?: () => void;
}

const SideDrawerProfile = (props: SideDrawerProfileProps) => {
  return (
    <StyledSideDrawerProfile
      className="flex items-center justify-between cursor-pointer"
      onClick={props.onProfileClick}>
      <div className="flex space-x-2 w-full">
        <div>
          <StyledProfileView className="flex justify-center items-center">
            {props.image ? (
              <StyledImage src={props.image}></StyledImage>
            ) : (
              <Typography
                label={`${props.firstName.charAt(0)}${props.lastName.charAt(
                  0,
                )}`}
                variant="f4"
                weight="semibold"
                color={colorTheme.light}></Typography>
            )}
          </StyledProfileView>
        </div>
        <div className="flex flex-col justify-center w-full flex1">
          <Typography
            className="overflow"
            label={`${props.firstName} ${props.lastName}`}
            variant="f2"
            weight="semibold"
            color={colorTheme.dark}></Typography>

          <Typography
              label={localization.sm_my_profile}
              variant="f1"
              color={colorTheme.darkFaded}></Typography>
        </div>
      </div>

      <div className="flex items-center">
        <Icon name="chevronRight" size={24} color={colorTheme.dark}></Icon>
      </div>
    </StyledSideDrawerProfile>
  );
};

export default SideDrawerProfile;
