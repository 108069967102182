import {useHistory, useLocation} from 'react-router-dom';
import {Typography} from '..';
import {colorTheme} from '../../configs';
import { NavItemList, navItems } from '../side-drawer/side-bar.types';
import { localization } from '../../../app/localization/Localization';
import { AnalyticsEvent, useAnalytics } from '../../../app/hooks/useAnalytics';
import { RoutePath } from '../../../app/navigation/config/RouteConfig';
import { isMobile } from 'react-device-detect';

interface NavBarMenusProps {
    menuList: NavItemList[];
  }

const NavBarMenus = (props: NavBarMenusProps) => {
  const history = useHistory();
  const location = useLocation();
  const {systemLogEvent} = useAnalytics();

  const handleClickMenu = (analyticsEvent?: AnalyticsEvent, disabled?: boolean, path?: RoutePath, url?: string) => {
    if (analyticsEvent) {
        systemLogEvent(analyticsEvent);
      }
      if (!disabled) {
        if (url) {
          window.open(url);
        } else {
          localStorage.setItem('referralListPageNumber', '1');
          history.push(path);
        }
      }
  }

  return (
    <div
        className='flex items-center gap-x-6'
    >
        {props.menuList.map((nav, i) => {
        return (
            <div
                key={i}
                className='cursor-pointer'
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (navItems[nav].analyticsClickEventName) {
                        systemLogEvent(navItems[nav].analyticsClickEventName as AnalyticsEvent);
                    }
                    if (!navItems[nav].disabled) {
                        if (navItems[nav].url) {
                            window.open(navItems[nav].url);
                        } else {
                            localStorage.setItem('referralListPageNumber', '1');
                            console.log(navItems[nav].path)
                            history.push(navItems[nav].path);
                        }
                    }
                }}
            >
                <Typography
                    label={localization.getString(navItems[nav].name)}
                    variant={isMobile ? 'f1' : 'f2'}
                    weight='semibold'
                    color={location.pathname === navItems[nav].path ? colorTheme.primary : colorTheme.dark}
                    // disabled={navItems[nav].disabled}
                />
            </div>
            
        );
        })}
    </div>
  );
};

export default NavBarMenus;
